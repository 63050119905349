import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import UseUsers from '../../../services/useUsers';

const BookingSchema = z.object({
  phone_number: z.string()
    .min(10, 'Phone number must be at least 10 digits')
    .regex(/^\+?[\d\s-]+$/, 'Invalid phone number format')
});

type BookingFormData = z.infer<typeof BookingSchema>;

interface BookSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const BookSessionModal = ({ isOpen, onClose, onSuccess }: BookSessionModalProps) => {
  const { updateProfile } = UseUsers();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BookingFormData>({
    resolver: zodResolver(BookingSchema)
  });

  const updateMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      toast.success('Contact details updated successfully');
      onSuccess();
      onClose();
    },
    onError: () => {
      toast.error('Failed to update contact details');
    }
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[70] flex items-center justify-center">
      <div className="bg-unimaytLight p-8 rounded-lg max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-light text-paragraph"
        >
          <XIcon />
        </button>

        <h2 className="text-smheading font-heading mb-4 text-light text-center">
          Book a Session with Our Experts
        </h2>
        
        <p className="text-paragraph text-light mb-6 text-center">
          Leave your contact details and our experts will get in touch with you shortly.
        </p>

        <form onSubmit={handleSubmit((data) => updateMutation.mutate(data))} className="space-y-4">
          <div>
            <label className="block text-subparagraph font-semibold text-light">
              Phone Number
              <input
                type="tel"
                {...register('phone_number')}
                className="w-full mt-1 border p-2 rounded-lg text-Button outline-none"
                placeholder="+1 (234) 567-8900"
              />
              {errors.phone_number && (
                <p className="text-red-500 text-sm">{errors.phone_number.message}</p>
              )}
            </label>
          </div>

          <button
            type="submit"
            className="w-full btn"
            disabled={updateMutation.isPending}
          >
            {updateMutation.isPending ? 'Submitting...' : 'Book Session'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookSessionModal;    