// In WelcomePopup.tsx
import React, { useState } from 'react';
import EditProfileModal from './EditProfileModel';
import BookSessionModal from './BookSessionModal';

const WelcomePopup = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  const handleStartProfileEdit = () => {
    setShowWelcome(false);
    setIsEditing(true);
  };

  const handleBookSession = () => {
    setIsBooking(true);
  };

  const handleBookingSuccess = () => {
    setShowWelcome(false);
  };

  return (
    <>
      {showWelcome && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[60] flex items-center justify-center">
          <div className="bg-unimaytLight p-8 rounded-lg max-w-md w-full mx-4">
            <h2 className="text-smheading font-heading mb-4 text-light text-center">
              Welcome to Unimayt!
            </h2>
            <p className="text-paragraph text-light mb-6 text-center">
              Choose how you'd like to get started with Unimayt.
            </p>
            <div className="space-y-4">
              <button 
                className="w-full btn"
                onClick={handleStartProfileEdit}
              >
                Complete Your Profile
              </button>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-unimaytLight text-light">OR</span>
                </div>
              </div>
              <button 
                className="w-full btn-outline"
                onClick={handleBookSession}
              >
                Book a One-on-One Session
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditing && (
        <EditProfileModal 
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          profile={null}
          isNewUser={true}
          setShowWelcome={setShowWelcome}
        />
      )}

      <BookSessionModal 
        isOpen={isBooking}
        onClose={() => setIsBooking(false)}
        onSuccess={handleBookingSuccess}
      />
    </>
  );
};

export default WelcomePopup;