import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UseUsers from "../../../services/useUsers";
import { XIcon } from "lucide-react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { OptionsOrGroups, GroupBase } from "react-select";
import useInstance from "./../../../services/instance";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import UseUniversities from "./../../../services/useUniversities";
import { Popover, PopoverContent, PopoverTrigger } from "./../../ui/poopover";
import YearSeasonSelector from "../YearSeasonSelector";
import useAuth from "./../../../services/useAuth";
import {UpdateSchema} from "../../../zodSchema/UpdateSchema"

type ProfileUpdateSchema = z.infer<typeof UpdateSchema>;

type UniversityOption = {
  value: string;
  label: string;
};

interface UserStatus {
  id: number;
  name: string;
}

interface Location {
  city: string;
  state: string;
}

interface EditProfileModalProps {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  profile?: ProfileUpdateSchema | null;
  isNewUser?: boolean;
  setShowWelcome?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  isEditing,
  setIsEditing,
  profile,
  isNewUser = false,
  setShowWelcome,
}) => {
  const { getUserStatuses, updateProfile } = UseUsers();
  const queryClient = useQueryClient();
  const animatedComponents = makeAnimated();
  const { instance: api } = useInstance();

  const [universityName, setUniversityName] = useState<string>(
    profile?.university_name || ""
  );
  const [course, setCourse] = useState<string>(profile?.course_name || "");
  const [userStatuses, setUserStatuses] = useState<UserStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number>(
    profile?.user_status_id || 1
  );
  const [campusLocation, setCampusLocation] = useState<string>(
    profile?.campus_location || ""
  );
  const [home, setHome] = useState<Location>();
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState<string>(
    profile?.course_start_date 
      ? profile.course_start_date.split(",")[1]?.trim() || new Date().getFullYear().toString()
      : new Date().getFullYear().toString()
  );
  const [season, setSeason] = useState<string>(
    profile?.course_start_date 
      ? profile.course_start_date.split(",")[0]?.trim() || "Spring"
      : "Spring"
  );

  const { getUniversitiesById } = UseUniversities();
  const { getLocations } = useAuth();
  
  const seasons = [
    { name: "Spring", icon: "🌸" },
    { name: "Summer", icon: "☀️" },
    { name: "Fall", icon: "🍂" },
    { name: "Winter", icon: "❄️" },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileUpdateSchema>({
    resolver: zodResolver(UpdateSchema),
    defaultValues: {
      user_status_id: profile?.user_status_id || 1,
      university_name: profile?.university_name || "",
      campus_location: profile?.campus_location || "",
      course_name: profile?.course_name || "",
      course_start_date: profile?.course_start_date || "",
      current_location: profile?.current_location || "",
      city_name: profile?.city_name || "",
    }
  });

  // Fetch user statuses only once on component mount
  useEffect(() => {
    let isMounted = true;
    const fetchUserStatuses = async () => {
      try {
        const response = await getUserStatuses();
        if (isMounted) {
          setUserStatuses(response.data);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch user statuses");
        }
      }
    };
    fetchUserStatuses();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (profile) {
      setValue("campus_location", profile.campus_location || "");
      setValue("course_name", profile.course_name || "");
      setValue("course_start_date", profile.course_start_date || "");
      setValue("current_location", profile.current_location || "");
      setValue("user_status_id", profile.user_status_id || 1);
      setValue("university_name", profile.university_name || "");
    }
  }, [profile, setValue]);

  // Auto fetch location for new users
  useEffect(() => {
    if (isNewUser && !home) {
      getLocation();
    }
  }, [isNewUser]);

  const onSubmit: SubmitHandler<ProfileUpdateSchema> = async (data) => {
    const formData: ProfileUpdateSchema = {
      user_status_id: selectedStatus,
      university_name: selectedStatus === 1 ? "" : universityName,
      campus_location: campusLocation,
      course_name: course.split('--')[0].trim(),
      course_start_date: `${season}, ${year}`,
      current_location: data.current_location || (home ? `${home.city}, ${home.state}` : ""),
      city_name: data.current_location || (home ? `${home.city}, ${home.state}` : ""),
    };

    try {
      // Validate the form data against the schema
      const validatedData = UpdateSchema.parse(formData);
      updateMutation.mutate(validatedData);
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          toast.error(err.message);
        });
      }
    }
  };

  const updateMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Update Successful");
      setIsEditing(false);
    },
    onError: () => {
      toast.error("Update failed");
    },
  });

  const debouncedFetchUniversities = _.debounce(
    async (
      inputValue: string,
      callback: (
        options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
      ) => void
    ) => {
      try {
        const response = await api.get(`/universities/`, {
          params: { q: inputValue, limit: 40 },
        });
        const options = response.data.map((university: any) => ({
          value: university.value,
          label: university.label,
        }));
        callback(options);
      } catch (error) {
        callback([]);
      }
    },
    500
  );

  const debouncedFetchCourses = _.debounce(
    async (
      inputValue: string,
      callback: (
        options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
      ) => void
    ) => {
      try {
        const response = await api.get(`/courses/`, {
          params: { q: inputValue, limit: 40 },
        });
        const options = response.data.map((course: any) => ({
          value: course.value,
          label: course.label,
        }));
        callback(options);
      } catch (error) {
        callback([]);
      }
    },
    500
  );

  const fetchUniversities = (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
    ) => void
  ) => {
    debouncedFetchUniversities(inputValue, callback);
  };

  const fetchCourses = (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
    ) => void
  ) => {
    debouncedFetchCourses(inputValue, callback);
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: state.isFocused ? "#000" : "#333",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#333",
    }),
  };

  const getCityFromUniversityMutation = useMutation({
    mutationFn: getUniversitiesById,
    onSuccess: (data) => {
      setCampusLocation(data.campus_location);
    },
  });

  const handleUniversitySelectChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.label : "";
    setUniversityName(value);
    setValue("university_name", value);
    if (selectedOption) {
      getCityFromUniversityMutation.mutate(selectedOption.value);
    }
  };

  const handleCourseSelectChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.label : "";
    setCourse(value);
    setValue("course_name", value);
  };

  const getLocationMutation = useMutation({
    mutationFn: getLocations,
    onSuccess: (data) => {
      setHome(data);
    },
  });

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by this browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        getLocationMutation.mutate(location);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          toast.error("Location permission denied by user.");
        }
      }
    );
  };

  if (!isEditing) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="relative bg-unimaytLight p-8 rounded-lg w-full max-w-lg md:w-100 md:h-auto h-full flex justify-center items-center flex-col lg:pt-12 lg:-mb-4">
        <div className="absolute top-6 w-full flex justify-between items-center px-4">
          {isNewUser && (
            <button
              onClick={() => {
                setIsEditing(false);
                setShowWelcome?.(true);
              }}
              className="text-light text-paragraph font-heading flex items-center gap-2"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                className="w-5 h-5"
              >
                <path d="M19 12H5M12 19l-7-7 7-7"/>
              </svg>
              Back
            </button>
          )}
          {!isNewUser && (
            <button
              onClick={() => setIsEditing(false)}
              className="ml-auto text-light text-paragraph font-heading"
            >
              <XIcon />
            </button>
          )}
        </div>

        <h2 className="mb-4 text-center text-smheading font-heading text-light">
          Edit Profile
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
          <label className="block text-subparagraph font-semibold text-light">
            User Status
            <select
              className="w-full mt-1 border p-2 rounded-lg text-Button outline-none"
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(Number(e.target.value));
                setValue("user_status_id", Number(e.target.value));
              }}
            >
              {userStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
            {errors.user_status_id && (
              <p className="text-red-500">{errors.user_status_id.message}</p>
            )}
          </label>

          {selectedStatus !== 1 && (
            <label className="block text-subparagraph font-semibold text-light">
              University Name
              <AsyncCreatableSelect
                cacheOptions
                components={animatedComponents}
                onChange={handleUniversitySelectChange}
                defaultOptions
                loadOptions={fetchUniversities}
                styles={customStyles}
                placeholder={profile?.university_name || "Enter your university name"}
                formatCreateLabel={(inputValue) => `other "${inputValue}"`}
                value={universityName ? { label: universityName, value: universityName } : null}
              />
              {errors.university_name && (
                <p className="text-red-500">{errors.university_name.message}</p>
              )}
            </label>
          )}

          <label className="block text-subparagraph font-semibold text-light">
          {selectedStatus== 1 ? "Target Country": "Campus Location"} 
            <input
              type="text"
              {...register("campus_location")}
              value={campusLocation}
              onChange={(e) => {
                setCampusLocation(e.target.value);
                setValue("campus_location", e.target.value);
              }}
              className="w-full mt-1 border p-2 rounded-lg text-Button outline-none"
              placeholder="Please type in your campus city"
              disabled={getCityFromUniversityMutation.isPending}
            />
            {errors.campus_location && (
              <p className="text-red-500">{errors.campus_location.message}</p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light">
          {selectedStatus== 1 ? "Academic program would you like to pursue": "Which course are you enrolled into"}
            <AsyncCreatableSelect
              cacheOptions
              components={animatedComponents}
              onChange={handleCourseSelectChange}
              defaultOptions
              loadOptions={fetchCourses}
              styles={customStyles}
              placeholder={profile?.course_name || "Enter your course name"}
              formatCreateLabel={(inputValue) => `other "${inputValue}"`}
              value={course ? { label: course, value: course } : null}
            />
            {errors.course_name && (
              <p className="text-red-500">{errors.course_name.message}</p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger className="w-full border my-1.5 mt-2 flex px-2 items-center justify-start py-2 rounded-md bg-white text-unimaytLight">
                {season && year ? `${season}, ${year}` : "When does the course start"}
              </PopoverTrigger>
              <PopoverContent>
                <YearSeasonSelector
                  selectSeasons={setSeason}
                  season={season}
                  selectYear={setYear}
                  year={year}
                  seasons={seasons}
                  onOpenChange={setOpen}
                />
              </PopoverContent>
            </Popover>
            {errors.course_start_date && (
              <p className="text-red-500">{errors.course_start_date.message}</p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light pb-4">
            Home Location
            <div className="flex items-center justify-between pz-3">
              <input
                type="text"
                {...register("current_location")}
                value={home ? `${home.city}, ${home.state}` : ""}
                className="w-full mt-1 p-2 rounded-lg text-Button outline-none"
                placeholder="City you call home"
                readOnly
              />
              {!isNewUser && (
                getLocationMutation.isPending ? (
                  <div className="w-10 flex py-2 items-center justify-center">
                    <img
                      src="/icons/ripples.svg"
                      alt="loading"
                      className="w-7 h-7"
                    />
                  </div>
                ) : (
                  <div
                    onClick={getLocation}
                    className="cursor-pointer h-12 p-2 flex items-center justify-center"
                  >
                    Find?
                  </div>
                )
              )}
            </div>
            {errors.current_location && (
              <p className="text-red-500">{errors.current_location.message}</p>
            )}
          </label>
          <button
            type="submit"
            className="w-full btn"
            disabled={updateMutation.isPending}
          >
            {updateMutation.isPending ? "Updating..." : "Update Profile"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;