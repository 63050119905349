import { z } from "zod";

export const UpdateSchema = z
  .object({
    user_status_id: z.number({
      required_error: "Student status is required",
      invalid_type_error: "Student status must be a number",
    }),
    university_name: z
      .string()
      .nullish(),
    campus_location: z
      .string()
      .min(1, "Campus location is required")
      .nullish(),
    course_name: z
      .string()
      .min(1, "Course name is required")
      .nullish(),
    course_start_date: z
      .string({ required_error: "Course Intake must be a valid date" })
      .nullish(),
    current_location: z
      .string()
      .min(1, "Home location is required")
      .nullish(),
    city_name: z
      .string()
      .nullish(),
  })
  .superRefine((data, ctx) => {
    
    if (data.user_status_id !== 1 && !data.university_name?.trim()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "University name is required",
        path: ["university_name"],
      });
    }

    if (
      data.user_status_id !== 1 && 
      (data.university_name === "I have an acceptance letter" ||
        data.university_name === "I have multiple acceptance letters")
    ) {
      if (!data.university_name?.trim()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "University name is required when you have an acceptance letter",
          path: ["university_name"],
        });
      }

      if (!data.course_start_date?.trim()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Course start date is required when you have an acceptance letter",
          path: ["course_start_date"],
        });
      }
    }
  });
